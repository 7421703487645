import { format } from 'date-fns';
import dayjs from 'dayjs';
import { ResizeDirection } from 're-resizable';
import { useEffect, useMemo, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { PointDataFrameDef } from '../../../model/definitions/PointDataFrameDef';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { SymbolLayerDef, SymbolSourceType } from '../../../model/definitions/SymbolLayerDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { WeatherDataSpaceDef } from '../../../model/definitions/WeatherDataSpaceDef';
import { GroupingEnum } from '../../../model/UI/enums/GroupingEnum';
import { ActiveDef, setElement, setLayerType } from '../../../store/slices/active-slice';
import { updateMapGRSLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { getLayerCentralTime, layerSpan } from '../helpers';
import { LayerLaneSlider } from './LayerLaneSlider';

interface LayerLaneProps {
  time: TimeControlDef;
  frames: Array<DataFrameDef>;
  layerType: MapLayers;
  duration: number;
  mapId: string;
  index: number;
  parentTime: TimeControlDef;
  parentId: string;
  enabled?: boolean;
  layerId: string;
  span?: { min: number; max: number };
  name?: string;
  layer: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
  layerSync: boolean;
  sceneStart?: number | null;
  sceneEnd?: number | null;
  space: WeatherDataSpaceDef;
}
export const LayerLane = ({
  time,
  layerType,
  duration,
  index,
  mapId,
  parentTime,
  parentId,
  enabled,
  layerId,
  span,
  name,
  layer,
  layerSync,
  sceneStart,
  sceneEnd,
  space,
  ...props
}: LayerLaneProps) => {
  console.log(
    dayjs((span?.min ?? 0) * 1000).format('YYYY-MM-DD HH:mm'),
    dayjs((span?.max ?? 0) * 1000).format('YYYY-MM-DD HH:mm'),
  );
  const { activeScene, grouping } = useSelector<RootState>((state) => state.active) as ActiveDef;

  const frames = useMemo(() => {
    if (
      layer.layerType === 'SYMBOL' &&
      // @ts-ignore
      layer.symbolSource.sourceType === SymbolSourceType.PointData
    ) {
      // @ts-ignore
      return layer.symbolSource.pointDataFrames.map((p: PointDataFrameDef) => {
        return {
          frameId: `${p.startDate}`,
          timestamp: p.startDate,
        } as DataFrameDef;
      });
    }
    return layer.dataFrames;
  }, [layer]);

  const isSynced = layerSync;
  const [timeCtrl, setTimeCtrl] = useState<TimeControlDef>(new TimeControlDef(0, parentTime.endMS));
  const { width, ref } = useResizeDetector();
  const dispatch = useDispatch();
  const percentToTime = (percent: number) => {
    return (duration * percent) / 100;
  };
  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  useEffect(() => {
    const ls = span;
    if (ls && sceneStart && sceneEnd) {
      ls.min = sceneStart / 1000;
      ls.max = sceneEnd / 1000;
    }
    if (isSynced && frames.length > 0 && layer.enabled) {
      setTimeCtrl(
        absoluteToPercent(
          [
            {
              ...time,
              startMS: getLayerCentralTime(parentTime, span!, layerSpan(frames), frames).start,
              endMS: getLayerCentralTime(parentTime, span!, layerSpan(frames), frames).end,
            },
          ],
          duration,
        )[0],
      );
      dispatch(
        updateMapGRSLayer({
          time: {
            ...time,
            startMS: getLayerCentralTime(parentTime, ls!, layerSpan(frames), frames).start,
            endMS: getLayerCentralTime(parentTime, ls!, layerSpan(frames), frames).end,
          },
          activeScene,
          mapId,
          layerType,
          index,
        }),
      );
    } else {
      const start = time.startMS; //< parentTime.startMS ? parentTime.startMS : time.startMS;
      const end = time.endMS; //> parentTime.endMS ? parentTime.endMS : time.endMS;
      layer.enabled &&
        setTimeCtrl(absoluteToPercent([{ ...time, startMS: start, endMS: end }], duration)[0]);
      if ((time.startMS < parentTime.startMS || time.endMS > parentTime.endMS) && layer.enabled) {
        dispatch(
          updateMapGRSLayer({
            time: {
              ...time,
              startMS: start,
              endMS: end,
            },
            activeScene,
            mapId,
            layerType,
            index,
          }),
        );
      }
    }
  }, [
    activeScene,
    duration,
    mapId,
    parentTime,
    isSynced,
    width,
    frames,
    frames.length,
    layerType,
    index,
    sceneStart,
    sceneEnd,
    span?.min,
    span?.max,
  ]);
  useEffect(() => {
    const start = time.startMS < parentTime.startMS ? parentTime.startMS : time.startMS;
    const end = time.endMS > parentTime.endMS ? parentTime.endMS : time.endMS;
    setTimeCtrl(absoluteToPercent([{ ...time, startMS: start, endMS: end }], duration)[0]);
    if (time.startMS < parentTime.startMS || time.endMS > parentTime.endMS) {
      dispatch(
        updateMapGRSLayer({
          time: {
            ...time,
            startMS: time.startMS,
            endMS: time.endMS,
          },
          activeScene,
          mapId,
          layerType,
          index,
        }),
      );
    }
  }, [
    time,
    width,
    duration,
    parentTime,
    open,
    layer.dataFrames,
    layer.dataFrameDensity,
    layer.dataFramesDensity,
    span,
    space,
  ]);
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
  ): void => {
    let payload: TimeControlDef = { ...timeCtrl };
    if (direction === 'left') {
      setTimeCtrl({ ...timeCtrl, startMS: getPercentFromRef(position, ref) });
      payload = { ...timeCtrl, startMS: getPercentFromRef(position, ref) };
      payload = {
        ...timeCtrl,
        startMS:
          parentTime.startMS > percentToTime(getPercentFromRef(position, ref))
            ? parentTime.startMS
            : percentToTime(getPercentFromRef(position, ref)),
        endMS: percentToTime(timeCtrl.endMS),
      };
    }
    if (direction === 'right') {
      setTimeCtrl({ ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) });
      payload = { ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) };
      payload = {
        ...timeCtrl,
        startMS: percentToTime(timeCtrl.startMS),
        endMS:
          parentTime.endMS < percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref))
            ? parentTime.endMS
            : percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref)),
      };
    }
    dispatch(
      updateMapGRSLayer({
        time: payload,
        activeScene,
        mapId,
        layerType,
        index,
      }),
    );
  };
  const dragEnded = (percent: number) => {
    let payload: TimeControlDef;
    if (absoluteToPercent([parentTime], duration)[0].startMS > percent) {
      setTimeCtrl({
        ...timeCtrl,
        startMS: absoluteToPercent([parentTime], duration)[0].startMS,
        endMS:
          absoluteToPercent([parentTime], duration)[0].startMS +
          (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(absoluteToPercent([parentTime], duration)[0].startMS),
        endMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].startMS +
            (timeCtrl.endMS - timeCtrl.startMS),
        ),
      };
    } else if (
      absoluteToPercent([parentTime], duration)[0].endMS <
      percent + (timeCtrl.endMS - timeCtrl.startMS)
    ) {
      setTimeCtrl({
        ...timeCtrl,
        startMS:
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        endMS: absoluteToPercent([parentTime], duration)[0].endMS,
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        ),
        endMS: percentToTime(absoluteToPercent([parentTime], duration)[0].endMS),
      };
    } else {
      setTimeCtrl({
        ...timeCtrl,
        startMS: percent,
        endMS: percent + (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(percent),
        endMS: percentToTime(percent + (timeCtrl.endMS - timeCtrl.startMS)),
      };
    }
    dispatch(
      updateMapGRSLayer({
        time: payload,
        activeScene,
        mapId,
        layerType,
        index,
      }),
    );
  };

  const openLayerProp = (
    mapId: string,
    layerType: 'grib' | 'radar' | 'satellite' | undefined,
    layerId: string,
  ) => {
    dispatch(
      setElement({
        activeElement: mapId,
        activeProp: 'mapPanels',
      }),
    );
    dispatch(
      setLayerType({
        mapId,
        layerType,
        layerId,
      }),
    );
  };
  const lane = (
    <Rnd
      enableResizing={{ left: !isSynced, right: !isSynced }}
      disableDragging={isSynced}
      minHeight={12}
      dragAxis={'x'}
      key={layerId}
      onClick={() =>
        openLayerProp(
          mapId,
          layerType === 'gribMapLayers'
            ? 'grib'
            : layerType === 'radarMapLayers'
            ? 'radar'
            : layerType === 'satelliteMapLayers'
            ? 'satellite'
            : undefined,
          layerId,
        )
      }
      resizeHandleComponent={{
        right: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(12px)',
              height: '100%',
            }}
          />
        ),
        left: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(2px)',
              height: '100%',
            }}
          />
        ),
      }}
      className={`segment ${layerType}_${index}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        height: 10,
        maxHeight: 10,
        minHeight: 10,
        background: enabled
          ? frames.length > 1
            ? '#0054ff'
            : frames.length === 0
            ? '#0054ff4D'
            : isSynced
            ? 'rgba(206,55,116,0.84)'
            : '#0054ff'
          : '#0054ff30',
      }}
      bounds={'parent'}
      position={{
        x: getNumber(timeCtrl.startMS, width),
        y: grouping === GroupingEnum.LOGICAL ? 4 : 6,
      }}
      size={{
        width: timeCtrl.endMS - timeCtrl.startMS + '%',
        height: 12,
      }}
      maxWidth={'100%'}
      onDragStop={(e, data) => {
        e && dragEnded(getPercentFromData(data, width));
      }}
      onResizeStop={(e, direction, ref, delta, position) =>
        resizeStopped(position, delta, ref, direction)
      }
    >
      {frames && <LayerLaneSlider frames={frames} layerId={layerId} />}
      <div className={'layer-hover'}>
        {frames && frames.length === 0 ? 'No Frames' : null}
        {frames && frames.length === 1
          ? format(frames[0]?.timestamp * 1000, 'dd.MMM, HH:mm')
          : frames.length > 1 &&
            `${format(frames[0]?.timestamp * 1000, 'dd.MMM, HH:mm')}-
           ${format(frames[frames.length - 1]?.timestamp * 1000, 'dd.MMM, HH:mm')}`}
        {isSynced && frames.length === 1 && <span> - FIX</span>}
      </div>
    </Rnd>
  );
  return (
    <div {...props} id={'Lane'} ref={ref}>
      <div className={'rail'} style={{ height: 1 }} />
      {lane}
    </div>
  );
};
